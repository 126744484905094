import React, { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import AnimatedContent from '../AnimatedContent/AnimatedContent';

import './Message.css';

const handleTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
};

const Message = ({ content, role, timestamp, status, index, retrySendMessage, prevTimestamp, stream, onStreamingComplete, messagesEndRef, isScrolledToBottom }) => {
  const showDateDivider = prevTimestamp === undefined || Math.floor(prevTimestamp / 86400) !== Math.floor(timestamp / 86400);

  useEffect(() => {
    if (isScrolledToBottom && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isScrolledToBottom, messagesEndRef]);

  const typingIndicator = <div className="typing-cursor"></div>;

  return (
    <>
      {/* {showDateDivider && <div className="date-divider">{handleTime(timestamp)}</div>} */}
      <div key={`${role}-${index}`} className={`message ${role} ${status}`}>
        <div className="message-text">
          {status === 'typing' && typingIndicator}
          {stream && (
            <span className={`text-background ${role}`}>
              <AnimatedContent
                content={content}
                animationType="stream"
                onAnimationComplete={onStreamingComplete}
              />
            </span>
          )}
          {!stream && status !== 'typing' && (
            <span className={`text-background ${role}`}>
              <ReactMarkdown>{content}</ReactMarkdown>
            </span>
          )}
          {status === 'failed' && (
            <button className="retry-button" onClick={() => retrySendMessage(index)}>
              Retry
            </button>
          )}
        </div>
      </div>
      <div ref={messagesEndRef}></div>
    </>
  );
};

export default Message;