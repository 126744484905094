import React, { useState, useEffect, useContext, useRef } from 'react';
import { ConversationContext } from './contexts/ConversationContext';
import './ChatbotWidget.css';
import LoadingSkeleton from './components/LoadingSkeleton/LoadingSkeleton';
import Message from './components/Message/Message';
import MessageInput from './components/MessageInput/MessageInput';
import useScrollAndLoad from './hooks/chat/useScrollAndLoad';
import Header from './components/Header/Header';
import ScrollArrowButton from './assets/scrolldown-button-white.svg';

const ChatbotWidget = () => {
  const { messages, sendMessage, retrySendMessage, isLoadingOlderMessages, loadMoreMessages, isLoading, updateMessage } = useContext(ConversationContext);
  const [isOpen, setIsOpen] = useState(false);
  const [messageListHeight, setMessageListHeight] = useState(72);
  const [newMessage, setNewMessage] = useState('');
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
  const [messageToStream, setMessageToStream] = useState(null);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [isMessageInputVisible, setIsMessageInputVisible] = useState(true);
  const messagesEndRef = useRef(null);
  const messagesListRef = useScrollAndLoad(messages, isLoadingOlderMessages, loadMoreMessages, isScrolledToBottom, setIsScrolledToBottom, isOpen, messageToStream);
  const messageInputContainerRef = useRef(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const previousScrollTop = useRef(0);

  useEffect(() => {
    const handleMessage = (event) => {
      switch (event.data.type) {
        case 'INITIALIZE':
          // Handle initialization if needed
          break;
        case 'OPEN_WIDGET':
          setIsOpen(true);
          break;
        case 'CLOSE_WIDGET':
          setIsOpen(false);
          break;
        case 'TOGGLE_WIDGET':
          setIsOpen(prev => !prev);
          break;
        default:
          break;
      }
    };

    window.addEventListener('message', handleMessage);
    window.parent.postMessage({ type: 'WIDGET_READY' }, '*');

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (messagesListRef.current) {
      handleMessageListHeight();
    }
  }, [messageListHeight]);

  const handleMessageListHeight = () => {
    const messageListArea = messagesListRef.current;
    if (messageListArea) {
      messageListArea.style.paddingBottom = `calc(var(--sab, 0px) + ${messageListHeight}px)`;
    }
  };

  useEffect(() => {
    if (isOpen) {
      scrollToBottom();
      if (initialLoading) {
        setInitialLoading(false);
      }
    }
    window.parent.postMessage({ type: 'WIDGET_STATE_CHANGE', isOpen }, '*');
  }, [isOpen, initialLoading]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      setIsScrolledToBottom(true);
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      const newMessageObj = {
        content: newMessage,
        timestamp: Math.floor(Date.now() / 1000),
        status: 'sending',
        role: 'user'
      };
      setInputDisabled(true);
      setMessageToStream(newMessageObj);
      setNewMessage('');
      await sendMessage(newMessage);
      setIsScrolledToBottom(false);
      scrollToBottom();
    }
  };

  useEffect(() => {
    if (!inputDisabled) {
      scrollToBottom();
    }
  }, [inputDisabled]);

  const handleStreamingComplete = (index) => {
    updateMessage(index, { stream: false });
    setInputDisabled(false);
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = messagesListRef.current;
    const isNearBottom = scrollHeight - scrollTop <= clientHeight + 50;
    const isAtBottom = scrollHeight - scrollTop === clientHeight;
    setIsScrolledToBottom(isNearBottom);

    setIsMessageInputVisible(scrollTop > previousScrollTop.current || isAtBottom);

    previousScrollTop.current = scrollTop;
  };

  useEffect(() => {
    const messagesList = messagesListRef.current;
    if (messagesList) {
      messagesList.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (messagesList) {
        messagesList.removeEventListener('scroll', handleScroll);
      }
    };
  }, [messagesListRef]);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    setMessageToStream(lastMessage && lastMessage.stream ? lastMessage : null);
  }, [messages]);

  return (
    <div className="chatbot-widget-container">
      <div className={`chat-window ${isOpen ? 'open' : ''}`}>
        <Header isOpen={isOpen} toggleChat={() => setIsOpen(!isOpen)} />
        <ul className="messages-list" ref={messagesListRef}>
          <div className="messages-list-content">
            {isLoadingOlderMessages && <div className='spinner-messages'><LoadingSkeleton /></div>}
            {!isLoading && messages.length > 0 ? (
              messages.map((msg, index) => (
                <Message
                  key={index}
                  {...msg}
                  index={index}
                  retrySendMessage={retrySendMessage}
                  prevTimestamp={index > 0 ? messages[index - 1].timestamp : undefined}
                  messagesEndRef={messagesEndRef}
                  onStreamingComplete={() => handleStreamingComplete(index)}
                  isScrolledToBottom={isScrolledToBottom}
                />
              ))
            ) : !isLoadingOlderMessages && <LoadingSkeleton />}
            <div ref={messagesEndRef} />
          </div>
        </ul>
        <div ref={messageInputContainerRef} className={`message-input-container`}>
          <MessageInput
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            setMessageListHeight={setMessageListHeight}
            messageListHeight={messageListHeight}
            handleSendMessage={handleSendMessage}
            inputDisabled={inputDisabled}
            isLoading={isLoading}
            isStreaming={messageToStream !== null}
            isOpen={isOpen}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatbotWidget;